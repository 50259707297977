import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { colors } from '@rsa-digital/evo-shared-components/theme/RSA/colors';
import { fonts } from '@rsa-digital/evo-shared-components/theme/RSA/fonts';
import styled from 'styled-components';
import talentLinkGlobalStyles, { secondaryButton } from '../styles';

const JobListContainer = styled.div`
  ${talentLinkGlobalStyles};

  #ResultsperpageFieldLabel + div {
    display: flex;
  }

  #lumesseJobsListWidget {
    .search-criteria {
      > div:first-child {
        ${fonts.headingMedium};
      }

      dt {
        ${fonts.headingXSmall};
        margin-top: ${spacing(2)};
      }

      .search-agent {
        padding-top: ${spacing(2)};
      }

      .search-agent a {
        ${secondaryButton}
        display: inline-block;
      }
    }

    .lumesse-jobs-list {
      margin-top: ${spacing(4)};

      caption {
        ${fonts.headingSmall};
        text-align: left;
        margin-bottom: ${spacing(4)};
      }

      table {
        text-align: left;
        width: 100%;

        th,
        td {
          padding: ${spacing(2)};
          border-bottom: 1px solid ${colors.neutral02};
        }

        thead {
          background-color: ${colors.core01};

          th {
            a {
              color: white;
              text-decoration: none;
            }
          }
        }

        tbody {
          tr {
            a {
              color: ${colors.core01};
              text-decoration: none;
            }
          }
        }
      }
    }

    .table-scroll-wrap {
      overflow: auto;
    }

    .pagination-wrapper {
      text-align: center;

      .pagination {
        display: inline-block;
        padding: 0;

        ul {
          text-align: center;
        }

        li {
          ${fonts.paragraphSmall};
          display: inline-block;
          margin-right: ${spacing(2)};
          width: 40px;
          height: 40px;
          border-radius: 32px;

          a {
            color: ${colors.neutral01};
            text-decoration: none;
            line-height: 40px;
            width: 100%;
            height: 100%;
            display: block;
            text-align: center;
          }

          &.active {
            background-color: ${colors.core01};

            a {
              color: ${colors.neutral08};
            }
          }
        }
      }
    }
  }
`;

export default JobListContainer;
