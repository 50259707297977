import React from 'react';
import JobListContainer from './styles';

const JobList: React.FC = () => (
  <JobListContainer>
    <div id="jobs-list" className="PSOFOShop container">
      <div className="row">
        <div className="twelve columns">
          <div id="lumesseJobsListWidget" className="list-style" />
        </div>
      </div>
    </div>
  </JobListContainer>
);

export default React.memo(JobList);
